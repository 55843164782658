import React, { useState, useEffect, useRef } from "react";
import SwitchCostume from "components/SwitchCostume";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import {
  TrashIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
import { get, put } from "network/http/api";
import { Modal, Button, UncontrolledAlert, Spinner } from "reactstrap";
import ModalContent from "./ModalContent";
import ModalActivationIntegration from "./ModalActivationIntegration";
import axios from "axios";
import ModalZendesk from "./ModalZendesk";
import CardContentTokopedia from "./CardMenuIntegrationContent/CardContentTokopedia";
import CardContentWhatsapp from "./CardMenuIntegrationContent/CardContentWhatsapp";
import { notificationErrorRequestAction } from "store/notification/actions";
import useWhatsAppSignup from "hooks/useWhatsAppSignup";

const CardMenuIntegration = ({ icon, item, name, setShowRightSidebar }) => {
  const history = useHistory();
  const modalRef = useRef(null);
  const [isOpenZendeskModal, setIsOpenZendeskModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showModalToggle, setShowModalToggle] = useState(false);
  const [showModalToggleActivation, setShowModalToggleActivation] =
    useState(false);
  const [typeModalOpen, setTypeModalOpen] = useState("");
  const [error, setError] = useState(null);
  const [showAction, setShowAction] = useState(false);

  useEffect(() => {
    if (item.scenario) {
      if (!item.scenario.is_disabled) {
        setEnabled(true);
      }
    }
  }, [item.scenario]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAction(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onOpenModalToggle = () => {
    setShowModalToggle(!showModalToggle);
  };

  const onOpenModalToggleActivation = () => {
    setShowModalToggleActivation(!showModalToggleActivation);
  };

  const onActivationPlatform = params => {
    setShowModalToggleActivation(true);
    setTypeModalOpen(params);
  };

  const onUpdateIntegration = () => {
    console.log("Update");
  };

  const openModalAction = () => {
    setShowAction(!showAction);
  };

  const onGoToCostume = () => {
    if (item.platform_name === "tokopedia") {
      history.push(`/integration/tokopedia`);
    }

    if (item.platform_name === "whatsapp") {
      useWhatsAppSignup()
    }

    if (item.platform_name === "zendesk") {
      setIsOpenZendeskModal(true);
    }

    if (item.platform_name === "web") {
      history.push(`/integration/web`);
    }

    if (item.platform_name === "facebook") {
      get("/platform-integration/facebook_login")
        .then(res => {
          const width = 700;
          const height = 800;
          const left = (window.innerWidth - width) / 2;
          const top = (window.innerHeight - height) / 2;
          const options = `width=${width},height=${height},left=${left},top=${top}`;
          window.open(res, "_blank", options);
        })
        .catch(err => {
          notificationErrorRequestAction(err.detail);
        });
    }

    if (item.platform_name === "shopee") {
      get("/integration/shopee/authorization-link")
        .then(res => {
          const width = 700;
          const height = 800;
          const left = (window.innerWidth - width) / 2;
          const top = (window.innerHeight - height) / 2;
          const options = `width=${width},height=${height},left=${left},top=${top}`;
          window.open(res.authorization_link, "_blank", options);
        })
        .catch(err => {
          notificationErrorRequestAction(err.detail);
        });
    }

    return;
  };

  const ContentCard = () => {
    if (item.platform_name.toLowerCase() === "tokopedia") {
      return (
        <CardContentTokopedia
          item={item}
          icon={icon}
          setShowRightSidebar={setShowRightSidebar}
        />
      );
    }

    // if (item.platform_name.toLowerCase() === "whatsapp") {
    //   return <CardContentWhatsapp item={item} icon={icon} />;
    // }

    return (
      <div
        className="page-integration-menu"
        onClick={() => onGoToCostume(item)}
      >
        <div className="page-integration-menu-title">
          {icon}
          <span className="title">{name}</span>
        </div>
        <div className="page-integration-action">
          {item.scenario ? (
            <div className="action" onClick={e => e.stopPropagation()}>
              <SwitchCostume
                enabled={enabled}
                setEnabled={() => onOpenModalToggle()}
                tooltipText="Mengubah pengaturan toogle akan mengaktifkan/ menonaktifkan integrasi platform Anda dengan sistem RISA AI."
              />
              <div
                className="action-menu"
                ref={modalRef}
                onClick={() => openModalAction()}
              >
                <EllipsisVerticalIcon className="icon-menu" />
                {showAction && (
                  <div className="action-modal">
                    <div
                      className="btn-icon-action btn-delete"
                      onClick={() => console.log("delete")}
                    >
                      <TrashIcon /> Hapus Integrasi
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="action-chevron"
              onClick={() => onActivationPlatform(item.platform_name)}
            >
              <ChevronRightIcon className="icon-chevron" />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ContentCard />
      <Modal
        isOpen={showModalToggle}
        toggle={onOpenModalToggle}
        className="modal-integration"
        centered
      >
        <ModalActivationIntegration
          enabled={enabled}
          name={item.platform_name}
          error={error}
          loading={loading}
          onUpdateIntegration={() => onUpdateIntegration()}
          setShowModalToggle={() => setShowModalToggle(false)}
        />
      </Modal>
      <Modal
        isOpen={showModalToggleActivation}
        toggle={onOpenModalToggleActivation}
        className="modal-integration modal-integration-activation"
        centered
      >
        <ModalContent
          type={typeModalOpen}
          onClose={() => setShowModalToggleActivation(false)}
        />
      </Modal>

      <ModalZendesk
        isOpen={isOpenZendeskModal}
        toggle={() => setIsOpenZendeskModal(!isOpenZendeskModal)}
      />
    </>
  );
};

export default CardMenuIntegration;
