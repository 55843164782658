import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import Tabs from "components/Tabs";
import StatusBar from "components/StatusBar";
import Profile from "components/Setting/Profile";
import ProfileCompany from "components/Setting/Company";
import Password from "components/Setting/Password";
// actions
import { getProfileDetail, getInvoices } from "store/actions";
import DisplayApiKey from "components/Setting/DisplayApiKey";
import Employee from "components/Setting/Employee";

const Settings = props => {
  const { profile, billing, isLoading, onGetProfileDetail, onGetInvoice } =
    props;
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = useState(null);

  const DataTabs = [
    {
      id: 0,
      name: "Informasi Pribadi",
      content: <Profile profile={profile} billing={billing} />,
      url: "profile",
    },
    {
      id: 1,
      name: "Profil Perusahaan",
      content: <ProfileCompany />,
      url: "company",
    },
    {
      id: 2,
      name: "Managemen Karyawan",
      content: <Employee />,
      url: "employee"
    },
    {
      id: 3,
      name: "Password",
      content: <Password profile={profile} billing={billing} />,
      url: "password",
    },
    {
      id: 4,
      name: "API Key",
      content: <DisplayApiKey />,
      url: "api-key",
    },
  ];

  useEffect(() => {
    onGetProfileDetail();
    onGetInvoice();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("tab");

    if (type === null || type === 'undefined') {
      setActiveTab(0);
    }

    const activeTabContent = DataTabs.find(tab => tab.url === type);
    if (!activeTab.id) {
      return
    }
    setActiveTab(activeTabContent.id);
  }, [location.search]);

  const handleTabClick = params => {
    const activeTabContent = DataTabs.find(tab => tab.id === params);
    setActiveTab(params);
    history.push(`/settings?tab=${activeTabContent.url}`);
  };

  return (
    <div className="page-content">
      <div className="page-settings">
        {status && (
          <StatusBar
            type={status?.type}
            title={status?.title}
            icon={status?.icon}
            onClose={() => setStatus(null)}
          />
        )}

        <Container fluid>
          <h1 className="title-page">Pengaturan</h1>
          <span className="subtitle-page">
            Anda dapat melakukan perubahan informasi personal, perusahaan dan
            juga password pada halaman ini.
          </span>
          <div className="page-settings-content">
            <Tabs
              tabs={DataTabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Profile, Invoice }) => {
  return {
    billing: Invoice.billing,
    profile: Profile.profile,
    isLoading: Profile.isLoading,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onGetProfileDetail: getProfileDetail,
    onGetInvoice: getInvoices,
  })(Settings)
);
